<template>
  <div class="contentView deliveryManageDetail">
    <div class="centerView">
      <a-form ref="filterFormRef" :model="filterForm" layout="inline">
        <a-form-item name="nameCode">
          <a-input v-model:value.trim="filterForm.nameCode" allowClear size="large" :placeholder="t('deliveryManageDetail.filterForm.nameCode')" />
        </a-form-item>
        <a-form-item>
          <a-space :size="16">
            <a-button type="primary" size="large" @click="search">{{ t('search') }}</a-button>
            <div class="form-item-button gray" @click="reset">{{ t('reset') }}</div>
          </a-space>
        </a-form-item>
      </a-form>
    </div>

    <div class="centerView order-info-wrap">
      <div class="address-info-wrap">
        <img class="address-info-img" src="@/assets/supplier/images/orderManage/address-icon.png" />
        <div class="address-info-detail">
          <div class="address-info-msg">
            <div>
              {{ t('addressInfo.receiver') }}：{{ detailData.receiverName || '-' }} - {{ t('addressInfo.phone') }}：{{ detailData.receiverPhone || '-' }} - {{ t('addressInfo.postal') }}：{{
                detailData.receiverPostcode || '-'
              }}
            </div>
            <div>{{ t('addressInfo.address') }}：{{ detailData.receiverAddress || '-' }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="centerView">
      <div class="flexC" style="margin-bottom: 16px">
        <div class="order-code flexL">
          <img src="@/assets/supplier/images/deliveryManage/order-code-icon.png" />
          #{{ detailData.shippingSn || '-' }}
        </div>
        <div class="operation-button">
          <a-space :size="20">
            <a-button
              v-if="(pageType == 'view' && detailData.status == 0) || pageType == 'add'"
              type="primary"
              size="large"
              class="padding-horizontal-12"
              @click="confirmShipmentFn"
              :loading="confirmShipmentSubmitting"
              >{{ t('deliveryManageDetail.delivery') }}</a-button
            >
            <div class="form-item-button gray padding-horizontal-12" @click="printFn">
              <LoadingOutlined v-if="printSubmitting" />
              {{ t('deliveryManageDetail.print') }}
            </div>
            <div class="form-item-button gray padding-horizontal-12" @click="exportShippingFn">
              <LoadingOutlined v-if="exportShippingSubmitting" />
              {{ t('deliveryManageDetail.download') }}
            </div>
          </a-space>
        </div>
      </div>
      <div class="invoice-list">
        <a-space :size="16" style="padding-right: 16px">
          <div class="invoice-item flexTL" v-for="item in detailData.expressListBos" :key="item.id">
            <div class="time">
              {{ item.deliveryAt || '-' }}
              <span class="status" :style="{ color: item.status == 10 ? '#ff6700' : '' }" v-if="item.status"
                >【{{ item.status && t(expressStatus.find((it) => it.value == item.status).label) }}】</span
              >
            </div>
            <span class="invoice-code">【{{ item.expressName || '-' }}】{{ item.expressCode || '-' }}</span>
          </div>
          <AddExpressModal v-if="[0, 10].includes(Number(detailData.status))" v-model:modalVisible="modalVisible" v-model:submitting="submitting" @submit="createExpressFn" class="invoice-add">
            <img class="icon" src="@/assets/supplier/images/deliveryManage/express-add-icon.png" />
            <span class="text">{{ t('deliveryManageDetail.addExpress') }}</span>
          </AddExpressModal>
        </a-space>
      </div>

      <a-spin tip="Loading..." :spinning="spinRef">
        <a-table
          v-if="!tableLoading"
          :dataSource="dataSource"
          :columns="columns"
          :loading="tableLoading"
          :pagination="false"
          row-key="id"
          size="small"
          class="ant-table-striped"
          :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          :scroll="{ x: 1300 }"
        >
          <template #productPicTemplate="{ text }">
            <ImgGallery :urls="[text]" :img-width="39" :img-height="52" :show-img-num="1" :show-more="false" />
          </template>
          <template #productName="{ text }">
            {{ text || '-' }}
          </template>
          <template #productParams="{ text }">
            <!-- <ImgGallery /> -->
            {{ (JSON.parse(text || '{}') || {}).color_name || '-' }} / {{ (JSON.parse(text || '{}') || {}).size_name || '-' }}
          </template>
          <template #deliveryQuantity="{ text }">
            {{ text || 0 }}
          </template>
          <template #receiptQuantity="{ record, text }">
            <a-space direction="vertical" :size="10">
              <span>{{ t('deliveryManageDetail.table.unReceive') }}：{{ record.unReceiptQuantity || 0 }}</span>
              <span>{{ t('deliveryManageDetail.table.receive') }}：{{ text || 0 }}</span>
            </a-space>
          </template>
          <template #qualifiedQuantity="{ record, text }">
            <a-space direction="vertical" :size="10">
              <span>{{ t('deliveryManageDetail.table.qualified') }}：{{ text || 0 }}</span>
              <span>
                {{ t('deliveryManageDetail.table.unqualified') }}：
                <span :style="{ color: record.unqualifiedQuantity ? '#ff6700' : '' }">{{ record.unqualifiedQuantity || 0 }}</span>
              </span>
            </a-space>
          </template>
        </a-table>
      </a-spin>

      <TableSkeleton v-if="tableLoading" />

      <pagination mt lable="" @change="onPageChange" :data="tableResult" @sizeChange="onSizeChange"></pagination>
    </div>

    <CustomModal
      modalWidth="380px"
      v-model:modalVisible="confirmShipmentModalVisible"
      :modalBodyTitle="t('deliveryManageDetail.delivery')"
      :modalBodyTips="t('deliveryManageDetail.deliveryTips')"
      :modalClosable="false"
      :modalCancelVisible="true"
      :modalOkText="t('confirm')"
      modalFooterJustify="center"
      :submitting="confirmShipmentSubmitting"
      @submit="confirmShipmentModelSubmit"
    />
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, reactive, watch, defineExpose, defineEmits } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import moment from 'moment'
import { message } from 'ant-design-vue'
import { LoadingOutlined } from '@ant-design/icons-vue'
import pagination from '@/components/pagination'
import TableSkeleton from '@/views/supplier/components/TableSkeleton'
import ImgGallery from '@/views/supplier/components/ImgGallery'
import AddExpressModal from '@/views/supplier/components/AddExpressModal'
import CustomModal from '@/views/supplier/components/CustomModal'
import { useI18n } from 'vue-i18n'
import { transformColumnsI18n } from '@/language'
import { initialColumns } from './config/deliveryTableDetailConfig'

import { getDeliveryManageDetail, createExpress, confirmShipment, exportShipping, getDeliveryManageDetailTableData } from '@/api/supplier/orderManagement/deliveryManage'
import { createExportTask, getTaskResult } from '@/api/supplier/data-workers'

// export default {
//   name: 'deliveryManageDetail',
//   components: {
//     pagination,
//     TableSkeleton,
//     AddExpressModal,
//     ImgGallery,
//     LoadingOutlined,
//     CustomModal,
//   },
//   setup() {
const emit = defineEmits(['closeModalUpdateList'])
const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n()

const isEdit = ref(false)

const pageType = ref()
const id = ref()

const detailData = ref({})
function getDeliveryManageDetailFn() {
  getDeliveryManageDetail({ id: id.value }).then((res) => {
    if (res.code == 200) {
      detailData.value = res.data
    }
  })
}

/* 快递状态 */
const expressStatus = [
  {
    label: 'deliveryManageDetail.expressStatus.1',
    value: 10,
  },
  {
    label: 'deliveryManageDetail.expressStatus.2',
    value: 20,
  },
]

const filterFormRef = ref()
const initialFilterForm = {}
const filterForm = ref({ shippingId: '', nameCode: null, page: 1, size: 10 })

/*  */

const columns = ref(transformColumnsI18n(initialColumns))
watch(locale, () => {
  columns.value = transformColumnsI18n(initialColumns)
})

const tableLoading = ref(false)
const dataSource = ref([])
const tableResult = ref(null)

function getTableData() {
  tableLoading.value = true

  tableResult.value = {}
  dataSource.value = []

  getDeliveryManageDetailTableData({ ...filterForm.value })
    .then((res) => {
      tableLoading.value = false
      if (res.code === 200) {
        tableResult.value = res.data
        dataSource.value = tableResult.value.data
      } else {
        // message.error(res.msg)
      }
    })
    .catch(() => {
      tableLoading.value = false
    })
}

const action = async (type, ID) => {
  console.log(ID, '123123-123123')
  pageType.value = type
  id.value = ID
  filterForm.value.shippingId = ID
  await getDeliveryManageDetailFn()
  await reset()
}

function reset() {
  filterForm.value = { ...filterForm.value, ...initialFilterForm }
  getTableData()
}
function search() {
  filterForm.value.page = 1
  getTableData()
}
function onPageChange(e) {
  filterForm.value.page = e
  getTableData()
}
function onSizeChange({ pageSize }) {
  filterForm.value.size = pageSize
  filterForm.value.page = 1
  getTableData()
}

/* 发货 */
const confirmShipmentModalVisible = ref(false)
const confirmShipmentSubmitting = ref(false)
function confirmShipmentFn() {
  confirmShipmentSubmitting.value = false
  confirmShipmentModalVisible.value = true
}
function confirmShipmentModelSubmit() {
  confirmShipmentSubmitting.value = true
  confirmShipment({ id: id.value })
    .then((res) => {
      confirmShipmentSubmitting.value = false
      if (res.code == 200) {
        message.success(t('opSuccess'))
        isEdit.value = true
        confirmShipmentModalVisible.value = false
        // router.back()
        emit('closeModalUpdateList')
      }
    })
    .catch((err) => {
      confirmShipmentSubmitting.value = false
    })
}

/* 打印 */
const printSubmitting = ref(false)
function printFn() {
  window.open(`${window.location.origin}/printInvoice?id=${id.value}`)
}

/* 导出发货明细 */
const exportShippingSubmitting = ref(false)
function exportShippingFn() {
  if (exportShippingSubmitting.value) return
  exportShippingSubmitting.value = true
  exportClick()
  // const { businessId } = detailData.value
  // exportShipping({ businessId })
  //   .then((res) => {
  //     exportShippingSubmitting.value = false
  //     if (res.code == 200) {
  //       message.success(t('opSuccess'))
  //     }
  //   })
  //   .catch((err) => {
  //     exportShippingSubmitting.value = false
  //   })
}

//点击导出订单明细
const spinRef = ref(false)
const downloadToken = ref() //导出明细token
const exportClick = async () => {
  downloadToken.value = ''
  let selelist = {
    shippingId: id.value,
  }
  let payload = {
    projectCode: 'SUPPLIER_ORDER_SHIPPING_DETAIL_EXPORT',
    dataParam: JSON.stringify(selelist),
  }
  const { code, data } = await createExportTask(payload)
  if (code == 200) downloadToken.value = data
  spinRef.value = true
  await getTaskResultFn(data)
}
const countNum = ref('')
//下载列表轮训
const getTaskResultFn = () => {
  getTaskResult({ taskToken: downloadToken.value })
    .then((res) => {
      if (res.code == 200) {
        // 任务状态
        //   0、待处理
        //   1、成功
        //   9、失败
        if (countNum.value > 5 && res.data.state == 0) {
          countNum.value = 0
          spinRef.value = false
          exportShippingSubmitting.value = false
          return message.error('下载失败，请稍后重试')
        }
        switch (res.data.state) {
          case 0:
            countNum.value++
            setTimeout(getTaskResultFn, 2000)
            break
          case 1:
            countNum.value = 0
            spinRef.value = false
            exportShippingSubmitting.value = false
            res.data.fileUrl && (window.location.href = res.data.fileUrl)
            break
          case 9:
            countNum.value = 0
            spinRef.value = false
            exportShippingSubmitting.value = false

            message.error(res.data.resultMessage || '下载失败')
            break
          default:
            break
        }
      }
    })
    .catch(() => {
      countNum.value = 0
      exportShippingSubmitting.value = false
      spinRef.value = false
    })
}

/* 添加快递 */
const submitting = ref(false)
const modalVisible = ref(false)
const createExpressFn = (e) => {
  const { id, shippingSn, businessId } = detailData.value
  submitting.value = true
  createExpress({ ...e, shippingId: id, shippingSn, businessId })
    .then((res) => {
      submitting.value = false
      if (res.code === 200) {
        message.success(t('opSuccess'))
        modalVisible.value = false
        getDeliveryManageDetailFn()
        isEdit.value = true
      } else {
        // message.error(res.msg)
      }
    })
    .catch(() => {
      submitting.value = false
    })
}

onBeforeRouteLeave((to, from, next) => {
  to.meta.isEdit = isEdit.value
  to.meta.isBack = true
  next()
})

//     return {
//       t,
//       pageType,
//       detailData,
//       filterForm,
//       columns,
//       dataSource,
//       tableResult,
//       tableLoading,
//       reset,
//       search,
//       onPageChange,
//       onSizeChange,
//       confirmShipmentFn,
//       confirmShipmentSubmitting,
//       confirmShipmentModalVisible,
//       confirmShipmentModelSubmit,
//       printFn,
//       printSubmitting,
//       exportShippingFn,
//       exportShippingSubmitting,
//       submitting,
//       modalVisible,
//       createExpressFn,
//       expressStatus,
//     }
//   },
// }
defineExpose({ action })
</script>

<style lang="less">
.deliveryManageDetail {
  position: relative;
  .centerView {
    // margin-bottom: 15px !important;
  }
  .select {
    margin: 0 !important;
  }
  .order-info-wrap {
    // display: flex;
    // align-items: flex-start;
    // justify-content: space-between;
  }
  .address-info-wrap {
    display: flex;
    width: 100%;
    padding: 24px 30px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #fafafa;
    .address-info-img {
      display: inline-block;
      width: 60px;
      height: 60px;
    }
    .address-info-detail {
      margin-left: 30px;
    }
    .address-info-msg {
      color: #131011;
      font-size: 16px;
      line-height: 30px;
    }
  }
  .order-code {
    height: 40px;
    flex: 1;
    margin-right: 20px;
    border-radius: 8px;
    background-color: #fafafa;
    color: #131011;
    font-size: 16px;

    img {
      display: inline-block;
      width: 16px;
      margin: 0 20px;
    }
  }
  .invoice-list {
    margin-bottom: 16px;
    // overflow: hidden;
    overflow-x: auto;
    .invoice-item,
    .invoice-add {
      height: 68px;
      padding: 14px 16px;
      border: 1px solid #fee5d4;
      border-radius: 4px;
      background-color: #fff5ec;
      line-height: 1;
      min-width: 140px;

      .time {
        color: #ff6700;
        font-weight: 400;
        font-size: 14px;
        white-space: nowrap;
      }

      .invoice-code {
        color: #131011;
        font-weight: 300;
        font-size: 14px;
      }

      .icon {
        width: 24px;
        margin-right: 12px;
      }

      .text {
        color: #ff6700;
        font-weight: 300;
        font-size: 14px;
      }
    }
    .invoice-add {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .ant-table-striped {
    background-color: #fff;
  }
}
</style>
