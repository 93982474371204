export const initialColumns = [
  {
    title: 'deliveryManageDetail.table.productPicTemplate',
    dataIndex: 'productPicTemplate',
    key: 'productPicTemplate',
    width: 80,
    slots: { customRender: 'productPicTemplate' },
  },
  {
    title: 'deliveryManageDetail.table.purchaseOrderSn',
    dataIndex: 'purchaseOrderSn',
    key: 'purchaseOrderSn',
    width: 180,
  },
  {
    title: 'deliveryManageDetail.table.manufacturingNo',
    dataIndex: 'manufacturingNo',
    key: 'manufacturingNo',
    width: 170,
  },
  {
    title: 'deliveryManageDetail.table.productParams',
    dataIndex: 'productParams',
    key: 'productParams',
    width: 150,
    slots: { customRender: 'productParams' },
  },
  {
    title: 'deliveryManageDetail.table.deliveryQuantity',
    dataIndex: 'deliveryQuantity',
    key: 'deliveryQuantity',
    width: 100,
  },
  {
    title: 'deliveryManageDetail.table.receiptQuantity',
    dataIndex: 'receiptQuantity',
    key: 'receiptQuantity',
    width: 150,
    slots: { customRender: 'receiptQuantity' },
  },

  {
    title: 'deliveryManageDetail.table.deliveryTime',
    dataIndex: 'deliveryTime',
    key: 'deliveryTime',
    width: 170,
  },
  {
    title: 'deliveryManageDetail.table.receiptTime',
    dataIndex: 'receiptTime',
    key: 'receiptTime',
    width: 170,
  },
  {
    title: 'deliveryManageDetail.table.qualifiedQuantity',
    dataIndex: 'qualifiedQuantity',
    key: 'qualifiedQuantity',
    width: 150,
    slots: { customRender: 'qualifiedQuantity' },
  },

  {
    title: 'deliveryManageDetail.table.sku',
    dataIndex: 'sku',
    key: 'sku',
    width: 150,
    // slots: { customRender: 'sku' },
  },
  {
    title: 'deliveryManageDetail.table.productName',
    dataIndex: 'productName',
    key: 'productName',
    width: 150,
    slots: { customRender: 'productName' },
  },
]
